<template>
  <div>My Account</div>
</template>

<script>
export default {
}

</script>

<style scoped>
</style>
